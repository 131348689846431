<template>
    <div class = "content">
        <mu-form  class="mu-demo-form" :model = 'postForm' label-position="left" label-width="100">
            <mu-form-item prop="num" label="table 数量">
                <mu-text-field v-model.number="postForm.num" type= 'number' :max = '20' :min = '2' @change = 'initColor'></mu-text-field>
            </mu-form-item>
            <mu-form-item prop="width" label="不足是否补全">
                <mu-radio style="margin-left: 16px" v-model="lackCenter" :value="true" label="是"></mu-radio>
                <mu-radio style="margin-left: 16px" v-model="lackCenter" :value="false" label="否"></mu-radio>
            </mu-form-item>
        </mu-form>
        <resize-box :list = 'list' :item-width="150" :lackCenter = 'lackCenter'>
            <div slot = 'item'
                 slot-scope="{item}"
                 class = "item"
                 :style = "{backgroundColor:item}"
                 @click = "initColor"
            ></div>
        </resize-box>

    </div>
</template>

<script>
import {randomColor} from '../../../utils/utils'

export default {
    name: "ResizeDemo",
    data:() => {
        return {
            list:[],
            postForm:{
                num:20,
            },
            lackCenter:true
        }
    },
    created() {
        this.initColor()
    },
    watch:{
       "postForm.num" :(num)=>{
           if(num){
               this.initColor()
           }
       }
    },
	mounted() {
		
	},
    methods:{
        initColor(){
            let arr = []
            for (let i = 0;i<this.postForm.num;i++){
                arr.push(randomColor())
            }
            this.list = arr
        }
    }
}
</script>

<style scoped lang="less">
.content{
    .item{
        // width:120px;
        margin:5px 10px;
        height: 120px;
        margin-bottom: 10px;
        // padding-top:calc(100% - 20px);
        transition: all .3s;
        border-radius: 5px;
    }
    .mu-demo-form{
        display: flex;
        justify-content: center;
        .mu-form-item{
            min-width: 200px;
            margin: 0 15px
        }
    }
}
</style>